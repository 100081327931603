import { mapState } from 'vuex'
import store from '@/store'
import { ElMessageBox, ElMessage, ElLoading } from 'element-plus'
export const unzip = {
  name: 'unzip',
  computed: {
    ...mapState({
      'unzipList': state => state.unzip.unzipList
    })
  },
  data() {
    return {
      notify: {} // 用来维护文件进度弹框对象
    }
  },
  watch: { // 监听进度列表
    unzipList: {
      handler(n) {
        const data = JSON.parse(JSON.stringify(n))
        data.forEach(item => {
          const domList = [...document.getElementsByClassName(item.time)]
          if (domList.find(i => i.className == item.time)) { // 如果页面已经有该进度对象的弹框，则更新它的进度
            if (item.progress === '失败') {
              domList.find(i => i.className == item.time).innerHTML = item.name + '压缩进度：压缩失败'
              const icon = document.createElement('img')
              icon.src = 'https://pic.imgdb.cn/item/67109038d29ded1a8cdb7347.png'
              icon.style.paddingLeft = '15px'
              icon.style.cursor = 'pointer'
              document.getElementsByClassName(item.time)[0].append(icon)

              icon.onclick = function() {
                document.getElementById('unzip').removeChild(document.getElementsByClassName(item.time)[0])
                store.commit('unzip/DEL_UNZIP', item.time)
              }
            } else {
              const unzipDiv = domList.find(i => i.className == item.time)
              domList.find(i => i.className == item.time).innerHTML = item.name + ' 压缩中,请等待...'
            }
          } else {
            if (item.progress === null) { // 此处容错处理，如果后端报错，删除当前进度对象
              this.$store.commit('unzip/DEL_UNZIP', item.time)
              return
            }
            const newDiv = document.createElement('div')
            newDiv.className = item.time
            newDiv.style.padding = '16px 24px'
            newDiv.style.backgroundColor = '#DCDCDC'
            newDiv.style.borderRadius = '6px'
            newDiv.style.marginBottom = '10px'
            // newDiv.innerHTML = item.name + '上传进度：' + item.progress + '%'
            this.notify[item.time] = document.getElementById('unzip').append(newDiv)
            if (item.progress === '失败') {
              newDiv.innerHTML = item.name + '压缩进度：压缩失败'
              const icon = document.createElement('img')
              icon.src = 'https://pic.imgdb.cn/item/67109038d29ded1a8cdb7347.png'
              icon.style.paddingLeft = '15px'
              document.getElementsByClassName(item.time)[0].append(icon)
              icon.style.cursor = 'pointer'
              icon.onclick = function() {
                document.getElementById('unzip').removeChild(document.getElementsByClassName(item.time)[0])
                setTimeout(() => {
                  delete this.notify[item.time]
                }, 1000)
                store.commit('unzip/DEL_UNZIP', item.time)
              }
            } else {
              newDiv.innerHTML = item.name + ' 压缩中,请等待...'
            }
          }
          if (item.progress == 100) { // 如果下载进度到了100%，关闭该弹框，并删除notify中维护的弹框对象
            document.getElementById('unzip').removeChild(document.getElementsByClassName(item.time)[0])
            // delete this.notify[item.time] 上面的close()事件是异步的，这里直接删除会报错，利用setTimeout，将该操作加入异步队列
            setTimeout(() => {
              delete this.notify[item.time]
            }, 1000)
            this.$store.commit('unzip/DEL_UNZIP', item.time)// 删除downLoadProgress中state的progressList中的进度对象
          }
        })
      },
      deep: true
    }
  }
}
